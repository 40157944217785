import { createSlice } from '@reduxjs/toolkit'
import { Document } from '@scaiview/sdk-typescript'
import { AnnotationCounter } from '../components/Annotation/utils/getAnnotations'

import { AppThunk } from '../store/types'

interface TerminologyName {
  value: string
  label: string
}

interface TerminologyColorscheme {
  annotation: string
  color: string
}

interface ResultsPageState {
  selectedDocuments: Array<Document>
  annotations: Array<AnnotationCounter>
  isAnnotationsLoading: boolean
  checkedAnnotations: Array<string>
  terminologyColorscheme: Array<TerminologyColorscheme>
  terminologyFullnames: Array<TerminologyName>
  page: number
  perPage: number
  sorting: string
  expandedAbstracts: boolean
  SAMLanguage: string
}

const initialState: ResultsPageState = {
  selectedDocuments: [],
  annotations: [],
  isAnnotationsLoading: false,
  checkedAnnotations: [],
  terminologyColorscheme: [],
  terminologyFullnames: [],
  page: 0,
  perPage: 10,
  sorting: 'relevance',
  expandedAbstracts: false,
  SAMLanguage: 'en',
}

const resultsPage = createSlice({
  name: 'resultsPage',
  initialState,
  reducers: {
    setSelectedDocuments(state, action) {
      const selectedDocuments = action.payload
      return { ...state, selectedDocuments }
    },
    setAnnotations: (state, action) => {
      const annotations = action.payload
      return { ...state, annotations }
    },
    setAnnotationsLoadingStatus: (state, action) => {
      const isAnnotationsLoading = action.payload
      return { ...state, isAnnotationsLoading }
    },
    setCheckedAnnotations: (state, action) => {
      const checkedAnnotations = action.payload
      return { ...state, checkedAnnotations }
    },
    setTerminologyColorscheme: (state, action) => {
      const terminologyColorscheme = action.payload
      return { ...state, terminologyColorscheme }
    },
    setTerminologyFullnames: (state, action) => {
      const terminologyFullnames = action.payload
      return { ...state, terminologyFullnames }
    },
    setPage: (state, action) => {
      const page = action.payload
      return { ...state, page }
    },
    setDocsPerPage: (state, action) => {
      const perPage = action.payload
      return { ...state, perPage }
    },
    setSorting: (state, action) => {
      const sorting = action.payload
      return { ...state, sorting }
    },
    expandAbstracts: (state, action) => {
      const expandedAbstracts = action.payload
      return { ...state, expandedAbstracts }
    },
    setSAMLanguage: (state, action) => {
      const SAMLanguage = action.payload
      return { ...state, SAMLanguage }
    },
  },
})

export const {
  setSelectedDocuments,
  setAnnotations,
  setAnnotationsLoadingStatus,
  setCheckedAnnotations,
  setTerminologyColorscheme,
  setTerminologyFullnames,
  setDocsPerPage,
  setPage,
  setSorting,
  expandAbstracts,
  setSAMLanguage,
} = resultsPage.actions
export default resultsPage.reducer

export const setAsyncTerminologyFullnames = (
  terminologyColorscheme: Array<TerminologyColorscheme>
): AppThunk => (dispatch) => {
  Promise.all(terminologyColorscheme).then((resp) =>
    dispatch(setTerminologyFullnames(resp))
  )
}
