import React from 'react'
import { PropagateLoader } from 'react-spinners'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

const PageSpinner = () => (
  <Container>
    <Row className="align-items-center">
      <Col className="text-center align-self-center">
        <PropagateLoader color="#009374" />
      </Col>
    </Row>
  </Container>
)

export default PageSpinner
