import { configureStore } from '@reduxjs/toolkit'

import { loadUser } from 'redux-oidc'
import rootReducer from '../reducers'

import userManager from '../utils/auth/userManager'

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})

// @ts-ignore
if (process.env.NODE_ENV === 'development' && module.hot) {
  // @ts-ignore
  module.hot.accept('../reducers', () => {
    const newRootReducer = require('../reducers').default
    store.replaceReducer(newRootReducer)
  })
}

// this should be placed directly after you configure your redux-store
loadUser(store, userManager)

export default store
