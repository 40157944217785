import React from 'react'
import { withRouter } from 'react-router-dom'

import { SignoutCallbackComponent } from 'redux-oidc'
import userManager from './userManager'

import { toast } from 'react-toastify'
import { PacmanLoader } from 'react-spinners'

const SignoutCallback = ({ history }) => {
  return (
    <SignoutCallbackComponent
      userManager={userManager}
      successCallback={() => {
        history.push('/')
      }}
      errorCallback={(error) => {
        history.push('/')
        toast.error(error)
      }}
    >
      <PacmanLoader color="#009374" />
    </SignoutCallbackComponent>
  )
}

export default withRouter(SignoutCallback)
