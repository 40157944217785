import React from 'react'

import { toast } from 'react-toastify'
import { GoAlert } from 'react-icons/go'

export const catchToastNotification = (error) => {
  console.log('Catched error:')
  console.log(error)
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.data.error) {
      toast.error(
        <span>
          <GoAlert size="2em" />
          There was an issue with your request!
        </span>
      )
    } else if (error.response.data) {
      toast.error(
        <span>
          <GoAlert size="2em" />
          There was an issue with your request!
        </span>
      )
    }
    console.log(error.response.data)
    console.log(error.response.headers)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    toast.error(error.request)
    console.log(error.request)
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error('Error', error.message)
    console.log('Error', error.message)
  }
  toast.error(error.config)
  console.log(error.config)
}
