export const GET_DOCUMENTS = 'GET_DOCUMENTS'
export const SET_USER_QUERY = 'SET_USER_QUERY'

export function setDocuments(data: Array<object>, status: number) {
  return { type: GET_DOCUMENTS, results: data, status }
}

export function setUserQuery(userQuery: Array<object>) {
  return { type: SET_USER_QUERY, payload: userQuery }
}
