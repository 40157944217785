import { combineReducers } from '@reduxjs/toolkit'

import { reducer as oidc } from 'redux-oidc'

import annotationReducer from '../slices/annotationSlice'
import search from './searchReducers'
import resultsPage from '../slices/resultsPageSlice'
// import paper from './paperReducers'

/**
 * aggregates all the other reducer...
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const rootReducer = combineReducers({
  oidc,
  search,
  resultsPage,
  paper: annotationReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
