import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import {
  Configuration,
  DocumentControllerApi,
  DocumentControllerApiFp,
  EntityStatisticsControllerApi,
  EntityStatisticsControllerApiFp,
  ExportApi as ExportSCAIViewApi,
  SearchControllerApi,
} from '@scaiview/sdk-typescript'

import {
  ConceptApi,
  Configuration as SAMConfig,
  ExportApi,
  RetrievalApi,
  TerminologyApi,
} from '@scaibio/sam-sdk-ts'
import { ControllerProvider } from '../api'

const APIconfig = new Configuration({
  basePath: window.REACT_APP_API_URL,
})

export const documentController = new DocumentControllerApi(APIconfig)
export const documentControllerFp = DocumentControllerApiFp(APIconfig)
export const entityStatisticsController = new EntityStatisticsControllerApi(
  APIconfig
)
export const entityStatisticsControllerFp = EntityStatisticsControllerApiFp(
  APIconfig
)
export const exportSCAIViewController = new ExportSCAIViewApi(APIconfig)
export const searchController = new SearchControllerApi(APIconfig)

const samConfig = new SAMConfig({
  basePath: window.REACT_APP_SAM_URL,
})
export const terminologyController = new TerminologyApi(samConfig)
export const conceptController = new ConceptApi(samConfig)
export const exportController = new ExportApi(samConfig)
export const retrievalController = new RetrievalApi(samConfig)

const APIRoute = (props) => {
  let { component: Component, ...rest } = props
  /* https://github.com/IdentityModel/oidc-client-js/wiki#user
        only redirect to a protected resource if user token is present and is not expired!
        This means, if the token expires the user can still view the current page but is
        redirected to the login page upon the next click.
      */
  return (
    <ControllerProvider
      value={{
        documentController,
        documentControllerFp,
        entityStatisticsController,
        entityStatisticsControllerFp,
        exportSCAIViewController,
        searchController,
        terminologyController,
        conceptController,
        exportController,
        retrievalController,
      }}
    >
      <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
    </ControllerProvider>
  )
}
export default withRouter(APIRoute)
