import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
// import PrivateRoute from './utils/auth/PrivateRoute'
// import SilentRenew from './utils/auth/SilentRenew'
import Callback from './utils/auth/Callback'
import SignoutCallback from './utils/auth/SignOutCallback'
import APIRoute from './utils/APIRoute'

import PageSpinner from './components/Layout/components/PageSpinner'

const LandingPage = React.lazy(() =>
  import(
    /* webpackChunkName: "components/Landing" */ './components/Layout/pages/LandingPage'
  )
)
const BasicFormSearch = React.lazy(() =>
  import(
    /* webpackChunkName: "components/BasicFormSearch" */ './components/Layout/pages/BasicFormSearch'
  )
)
const Results = React.lazy(() =>
  import(
    /* webpackChunkName: "components/Results" */ './components/Layout/pages/results'
  )
)
const DocumentPage = React.lazy(() =>
  import(
    /* webpackChunkName: "components/Document" */ './components/Layout/pages/DocumentPage'
  )
)
const CorpusStatisticsView = React.lazy(() =>
  import(
    /* webpackChunkName: "components/Stats" */ './components/Layout/pages/CorpusStatisticsView'
  )
)
const NotFoundPage = React.lazy(() =>
  import('./components/Layout/pages/NotFoundPage')
)

const SupportPage = React.lazy(() =>
  import('./components/Layout/pages/SupportPage')
)

const App = () => (
  <BrowserRouter>
    <Suspense fallback={PageSpinner}>
      <Switch>
        <Route exact path="/support" component={SupportPage} />
        <APIRoute exact path="/" component={BasicFormSearch} />
        <APIRoute path="/search" component={BasicFormSearch} />
        <APIRoute path="/results" component={Results} />
        <APIRoute
          component={DocumentPage}
          path="/document/:documentID"
          mode="FULLTEXT"
        />
        <APIRoute
          component={DocumentPage}
          path="/mindmap/:documentID"
          mode="MINDMAP"
        />
        <APIRoute path="/entity" component={CorpusStatisticsView} />
        <Route path="/login" component={Callback} />
        <Route path="/logout" component={SignoutCallback} />
        {/* <Route path="/silentRenew" component={SilentRenew} /> */}
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  </BrowserRouter>
)
export default App
