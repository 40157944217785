import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AnnotationState {
  annotations: Array<string>
}

const initialState: AnnotationState = {
  annotations: [],
}

const annotations = createSlice({
  name: 'annotations',
  initialState,
  reducers: {
    setAnnotations(state, action: PayloadAction<Array<string>>) {
      state.annotations = action.payload
    },
    resetAnnotations(state) {
      state.annotations = []
    },
  },
})

export const { setAnnotations, resetAnnotations } = annotations.actions
export default annotations.reducer
