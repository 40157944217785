import { createUserManager, loadUser } from 'redux-oidc'
import store from '../../store'

// the user manager settings for oidc-client
const userManagerSettings = {
  authority: `${window.REACT_APP_KEYCLOAK_URL}/realms/${window.REACT_APP_KEYCLOAK_REALM}/.well-known/openid-configuration`,
  client_id: window.REACT_APP_KEYCLOAK_CLIENTID,
  redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port && `:${window.location.port}`}/login`,
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port && `:${window.location.port}`}/logout`,
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port && `:${window.location.port}`}/silentRenew`,
  scope: 'openid',
  response_type: 'id_token token',
  mode: 'redirect',

  /*
  will renew the access token (the one used as a Bearer Token)
  but only as long as the session is valid, if not -> user must re-authenticate via login
  a session gets invalid, if the user performs a logout or the session reached the idle timeout (if no application within the realm requested an authentication)
  */
  automaticSilentRenew: true,
  filterProtocolClaims: false,
  loadUserInfo: true,
  accessTokenExpiring: 10,
  revokeAccessTokenOnSignout: true,
}

const userManager = createUserManager(userManagerSettings)
userManager.clearStaleState()
// userManager.signinSilent()
export default userManager
