import React from 'react'
import { Redirect } from 'react-router'

import _ from 'lodash'

import userManager from './userManager'

export const onLogin = (event) => {
  event.preventDefault()
  userManager.signinRedirect()
}

export const isAuthenticated = (user) => {
  return !_.isNil(user) && !user.expired
}

export const redirectToLoginPage = (previosLocation) => {
  if (!_.isNil(previosLocation)) {
    sessionStorage.setItem('redirect', previosLocation)
  }
  return <Redirect to={{ pathname: '/' }} />
}

export const redirectToPreviousPage = () => {
  const from = sessionStorage.getItem('redirect') || '/search'
  sessionStorage.removeItem('redirect')
  return from
}
