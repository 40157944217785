import React from 'react'
import { withRouter } from 'react-router-dom'

import { CallbackComponent } from 'redux-oidc'
import userManager from './userManager'

import { toast } from 'react-toastify'
import { PacmanLoader } from 'react-spinners'
import { redirectToPreviousPage } from './index'

const CallbackPage = ({ history }) => {
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => {
        history.push(redirectToPreviousPage())
      }}
      errorCallback={(error) => {
        history.push('/')
        toast.error(error)
      }}
    >
      <PacmanLoader color="#009374" />
    </CallbackComponent>
  )
}

export default withRouter(CallbackPage)
