import { GET_DOCUMENTS, SET_USER_QUERY } from '../actions/searchActions'

const initialState = {
  userQuery: {},
  results: {},
  status: 0,
}

function searchReducers(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTS:
      return Object.assign({}, state, {
        results: action.results,
        status: action.status,
      })
    case SET_USER_QUERY:
      return Object.assign({}, state, {
        userQuery: action.payload,
      })
    default:
      return state
  }
}

export default searchReducers
