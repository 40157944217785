import React, { createContext } from 'react'

export const ControllerContext = createContext({})
export const ControllerProvider = ControllerContext.Provider
export const ControllerConsumer = ControllerContext.Consumer

export function withController(Component) {
  return function WrapperComponent(props) {
    return (
      <ControllerConsumer>
        {(state) => <Component {...props} context={state} />}
      </ControllerConsumer>
    )
  }
}
